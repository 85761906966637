@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Lexend:wght@400;500;600;700&display=swap');

:root {
  --bg-grid-color: rgba(0, 0, 0, 0.05);
  --blue-500: rgb(59, 130, 246);
  --blue-500-rgb: 59, 130, 246;
  
  --indigo-500: rgb(99, 102, 241);
  --indigo-500-rgb: 99, 102, 241;
  
  --purple-500: rgb(139, 92, 246);
  --purple-500-rgb: 139, 92, 246;
  
  --green-500: rgb(16, 185, 129);
  --green-500-rgb: 16, 185, 129;
  
  --gray-500: rgb(107, 114, 128);
  --gray-500-rgb: 107, 114, 128;
}

/* Base styles */
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-white text-gray-800;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lexend', sans-serif;
}

/* Custom animated background */
.animated-gradient {
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Dot pattern background */
.bg-dot-pattern {
  background-image: radial-gradient(var(--bg-grid-color) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Grid pattern backgrounds */
.bg-grid-pattern {
  background-image: linear-gradient(var(--bg-grid-color) 1px, transparent 1px),
    linear-gradient(to right, var(--bg-grid-color) 1px, transparent 1px);
  background-size: 50px 50px;
}

.bg-grid-pattern-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Glassmorphism effect for cards and containers - updated for light theme */
.glass-morphism {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

/* Custom transitions */
.transition-all-cubic {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Custom animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Text styles */
.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r;
}

.heading-lg {
  @apply text-4xl md:text-6xl font-bold;
}

.heading-md {
  @apply text-2xl md:text-4xl font-bold;
}

/* Card styles - updated for light theme */
.card {
  @apply bg-white/90 backdrop-blur-md border border-gray-200 rounded-xl overflow-hidden hover:shadow-xl transition-all duration-300;
}

.card-hover {
  @apply hover:bg-white hover:border-gray-300 hover:shadow-lg hover:shadow-blue-200/30;
}

/* Button styles */
.btn {
  @apply px-6 py-3 rounded-lg transition-all font-medium;
}

.btn-primary {
  @apply bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg hover:shadow-blue-200/50;
}

.btn-secondary {
  @apply bg-gray-200 backdrop-blur-md text-gray-700 hover:bg-gray-300;
}

/* Hover transition effect for cards */
.hover-lift {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
}

/* Enhanced shadows for depth */
.shadow-glow {
  box-shadow: 0 0 15px 2px rgba(59, 130, 246, 0.15);
}

.shadow-glow-purple {
  box-shadow: 0 0 15px 2px rgba(139, 92, 246, 0.15);
}

.shadow-glow-green {
  box-shadow: 0 0 15px 2px rgba(16, 185, 129, 0.15);
}

/* Improved glassmorphism effect with better translucency - updated for light theme */
.glass-card {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
}

/* Enhanced soft gradient backgrounds - updated for light theme */
.bg-soft-blue-gradient {
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.08) 0%, rgba(59, 130, 246, 0.04) 100%);
}

.bg-soft-purple-gradient {
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.08) 0%, rgba(139, 92, 246, 0.04) 100%);
}

.bg-soft-indigo-gradient {
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.08) 0%, rgba(99, 102, 241, 0.04) 100%);
}

/* Subtle text shadows for headings to improve contrast - reduced for light theme */
.text-shadow-sm {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-lg {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Enhanced chart tooltips - updated for light theme */
.chart-tooltip {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
  padding: 10px 14px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  color: #1f2937 !important;
  font-family: 'Inter', sans-serif !important;
}

/* Animated gradient border effect - softer for light theme */
.animated-border-gradient {
  position: relative;
  border-radius: 0.75rem;
  z-index: 0;
}

.animated-border-gradient::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 0.85rem;
  background: linear-gradient(45deg, 
    rgba(59, 130, 246, 0.5), 
    rgba(139, 92, 246, 0.5), 
    rgba(16, 185, 129, 0.5), 
    rgba(59, 130, 246, 0.5));
  background-size: 200% 200%;
  animation: gradient 10s linear infinite;
}

/* Subtle pulsing animation - lighter for light theme */
@keyframes pulse-subtle {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
}

.animate-pulse-subtle {
  animation: pulse-subtle 2s infinite;
}

/* More subtle grid pattern - updated for light theme */
.bg-micro-grid {
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.03) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Improved data card - updated for light theme */
.data-card {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.data-card:hover {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}